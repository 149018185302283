<template>
    <div>
        <progressdialog ref="progress" />
        <div class="pd-x-20 pd-sm-x-30 pd-t-20 pd-sm-t-30">
            <div class="row">
                <div class="col-6">
                    <h4 class="tx-gray-800 mg-b-5">{{customername}}-{{$t('title.users')}} </h4>
                </div>
                <div class="col-6">
                    <div class="br-pageheader float-right">
                        <nav class="breadcrumb pd-0 mg-0 tx-12 float-right">
                            <router-link class="breadcrumb-item" :to="{name:'customers'}">{{ $t('title.customers') }}</router-link>
                            <span class="breadcrumb-item active">{{$t('title.users')}}</span>
                        </nav>
                    </div><!-- br-pageheader -->
                </div>
            </div>
            <div class="row">
                <div class="col-6">

                </div>
                <div class="col-6">
                    <div class="float-right">
                        <a @click="startEdit(0)" class="btn btn-info">{{ $t('actions.add') }}</a>
                    </div>
                    <div class="float-right">
                        <button @click="exportusers" class="btn btn-success mg-r-10">{{ $t('actions.exportusers') }}</button>
                    </div>
                    <div class="float-right">

                        <spinner v-if="fileuploading" height-class="ht-50"></spinner>
                        <button v-else type="button" @click="openfiledialog" class="btn btn-success mg-r-10">
                            <font-awesome-icon icon="folder-plus" /> {{ $t('actions.importfile') }}
                        </button>
                        <input style="display: none"
                               type="file"
                               id="fileLoader"
                               ref="file"
                               title="Select File"
                               @change="importfile"
                               accept=".txt, .csv, .xlsx, .zip" />
                    </div>
                    <div class="float-right">
                        <button :disabled="users.length==0" @click="sendwelcomemail" class="btn btn-success mg-r-10">{{ $t('actions.welcomemail') }}</button>
                    </div>

                    <div class="float-right form-group mg-b-0 mg-r-10">
                        <search :search="search" @search="doSearch"></search>
                    </div><!-- form-group -->
                    <div class="float-right mg-r-10">
                        <button class="btn btn-info" id="saveProject" @click="fetch" type="button"><font-awesome-icon icon="sync" /></button>
                    </div>
                </div>
            </div>
        </div>
        <div class="br-pagebody">
            <div class="br-section-wrapper">
                <div class="table-responsive">
                    <table v-if="initialized" class="table table-hover table-striped mg-b-0">
                        <thead>
                            <tr>

                                <th class="wd-50">
                                    <input type="checkbox" :checked="users.length>0 && users.length==list.length" @change="masterusers($event.target.checked)" />
                                </th>
                                <th>{{ $t('name') }}</th>
                                <th>{{ $t('email') }}</th>
                                <th>{{ $t('createdon') }}</th>
                                <th class="text-right" width="200">{{ $t('title.details') }}</th>
                            </tr>
                        </thead>
                        <tbody v-if="list.length > 0">
                            <tr :class="record.isActive==true?'':'tx-gray-400'" v-for="record in list" :key="record.id">
                                <td class="wd-50"><input type="checkbox" :checked="users.some(x=>x==record.id)" value="record.id" @change="updateUser($event.target.checked,record.id)" /></td>
                                <td>{{record.name}} </td>
                                <td>{{record.email}}</td>
                                <td>{{record.createdOn|date}}</td>
                                <td class="text-right" width="200">
                                    <button class="btn btn-icon btn-success mg-r-15" @click="startEdit(record.id)"><font-awesome-icon icon="edit" /></button>
                                    <button class="btn btn-icon btn-danger mg-r-15" @click="deleteRecord(record.id)"><font-awesome-icon icon="trash" /></button>

                                </td>
                            </tr>
                        </tbody>
                        <tbody v-else>
                            <tr>
                                <td colspan="5">{{ $t('status.norecords') }}</td>
                            </tr>
                        </tbody>
                    </table>
                    <p v-else><em>{{ $t('status.loading') }}</em></p>
                </div>
                <paging v-if="initialized" :current-page="page" :total-pages="totalPages" @page-changed="goToPage" />
            </div><!-- br-section-wrapper -->
        </div><!-- br-pagebody -->
    </div>
</template>

<script>
    import { LIST_SEARCH, LIST_PAGE } from '@/store/actions/listactions';
    import { AUTH_REQUEST_IMPERSONATE } from '@/store/actions/authactions';
    import { mapGetters, mapActions } from 'vuex';
    import toastr from 'toastr';
    import FileDownload from 'js-file-download';

    export default {
        name: 'CustomerUserList',
        data() {
            return {
                initialized: false,
                totalPages: 0,
                list: [],
                users: [],
                fileuploading: false,
                customername: ''
            };
        },
        props: ['id'],
        mounted() {
            this.goToPage(0);
            this.fetch();

        },
        watch: {
            search() {
                this.fetch();
            },
            page() {
                this.fetch();
            },
        },
        methods: {
            masterusers(val) {
                if (val) {
                    this.users = this.list.map(x => x.id);
                }
                else {
                    this.users = [];
                }
            },
            startEdit(id) {
                this.$router.push({ name: 'customerUsersEdit', params: { id: this.id, userId: id } });
            },
            async fetch() {

                if (this.$route.query.add) {
                    this.$router.push({ name: 'customerUserEdit', params: { id: -1 } });
                }
                const client = await this.$api.getClient();
                const resp = await client.GetCustomerUsers({ id: this.id, page: this.page, search: this.search });
                this.list = resp.data.list;
                this.totalPages = resp.data.totalPages;
                this.customername = resp.data.title;
                this.initialized = true;

            },
            async deleteRecord(id) {
                this.$dialog.confirm(this.$t('confirm.delete'))
                    .then(async () => {
                        const client = await this.$api.getClient();
                        try {
                            await client.DeleteCustomerUser(id);
                            toastr.success(this.$t('success.deleteRecord'), '');
                            await this.fetch();
                        } catch (err) {
                            toastr.error(this.$t('error.deleteRecord'), err);
                        }
                    })
                    .catch(() => { });
            },
            ...mapActions('auth', {
                doLogin: AUTH_REQUEST_IMPERSONATE
            }),
            ...mapActions('customeruserlist', {
                doSearch: LIST_SEARCH,
                goToPage: LIST_PAGE
            }),
            updateUser(val, id) {
                if (val) {
                    this.users.push(id);
                }
                else
                    this.users = this.users.filter(x => x != id);


            },
            async sendwelcomemail() {
                const client = await this.$api.getClient();
                try {

                    await client.SendWelcomeMail(null, this.users);

                    toastr.success(this.$t('success.saveChanges'), '');
                } catch (err) {
                    toastr.error(this.$t('error.saveChanges'), err);
                }
            },
            async importfile() {
                let self = this;
                let client = await this.$api.getClient();
                let formData = new FormData();
                let file = this.$refs.file.files[0];
                formData.append('file', file);
                client
                    .SaveFile({ id: self.$route.params.id, uniqid: this.$refs.progress.guid }, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                    })
                    .then(() => {
                        self.fetch();
                        toastr.success(this.$t('success.saveChanges'), '');
                    });

            },
            async exportusers() {
                let self = this;
                let client = await this.$api.getClient();
                this.$refs.progress
                    .start()
                    .then(() => {
                        client
                            .ExportUsers({ id: self.$route.params.id, uniqid: this.$refs.progress.guid }, null, {
                                responseType: 'arraybuffer'
                            })
                            .then((resp) => {
                                FileDownload(resp.data, "user.txt");
                                this.$refs.progress.stop();
                                toastr.success(this.$t('success.saveChanges'), '');
                            });
                    })
                    .catch((err) => {
                        toastr.error(this.$t('error.saveChanges'), err);
                        this.$refs.progress.stop();
                    })
                    .finally(() => {
                        console.log('complete');
                    });
            },
            openfiledialog() {
                this.$refs.file.click();
            },

        },
        computed: {
            ...mapGetters('customeruserlist', [
                'page',
                'search'
            ]),

        }
    }
</script>
