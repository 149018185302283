<template>
    <div v-if="initialized">
        <div class="pd-x-20 pd-sm-x-30 pd-t-20 pd-sm-t-30">
            <div class="row">
                <div class="col-6">
                    <h4 class="tx-gray-800 mg-b-5">{{ $t('title.customerusers') }} </h4>
                </div>
                <div class="col-6">
                    <div class="br-pageheader float-right">
                        <nav class="breadcrumb pd-0 mg-0 tx-12 float-right">
                            <router-link class="breadcrumb-item" :to="{name:'customers'}">{{ $t('title.customers') }}</router-link>
                            <router-link class="breadcrumb-item" :to="{name: 'customerUsers', params: { id: $route.params.id }}">{{ $t('title.customerusers') }}</router-link>
                            <span class="breadcrumb-item active">{{record.name}}</span>
                        </nav>
                    </div><!-- br-pageheader -->
                </div>
            </div>
        </div>

        <form @submit.prevent="saveChanges">
            <div class="br-pagebody">
                <div class="br-section-wrapper">

                    <h6 class="tx-gray-800 tx-uppercase tx-bold tx-14 mg-b-10">{{ $t('actions.edit') }}</h6>
                    <div class="row mg-t-15">
                        <div class="col-4">
                            <div class="input-group">
                                <label class="form-control-label" :for="$t('email')">{{$t('email')}}:</label><span class="tx-danger">*&nbsp;</span>
                            </div>
                        </div>
                        <div class="col-8 mg-t-10 mg-sm-t-0">
                            <input class="form-control form-control-sm" v-model="record.email" name="email" type="email" />
                        </div>
                    </div>


                    <div class="row mg-t-15">
                        <div class="col-4 ">
                            <div class="input-group">
                                <label class="form-control-label" :for="$t('name')">{{$t('name')}}:</label>
                            </div>
                        </div>
                        <div class="col-8 mg-t-10 mg-sm-t-0">
                            <input class="form-control form-control-sm" v-model="record.name" name="name" />
                        </div>
                    </div>

                    <div class="row mg-t-15">
                        <div class="col-4 ">
                            <div class="input-group">
                                <label class="form-control-label" :for="$t('active')">{{$t('active')}}:</label>
                            </div>
                        </div>
                        <div class="col-8 mg-t-10 mg-sm-t-0">                            
                            <inputtoggle v-model="record.isActive" style="vertical-align:middle"></inputtoggle>
                        </div>
                    </div>

                    <div class="row mg-t-15">
                        <div class="col-4 ">
                            <div class="input-group">
                                <label class="form-control-label" :for="$t('password')">{{$t('password')}}:</label>
                            </div>
                        </div>
                        <div class="col-6 mg-t-10 mg-sm-t-0">
                            <input class="form-control form-control-sm" v-model="record.password" name="password" />
                        </div>
                        <div class="col-2">
                            <a @click="generatepassword" class="btn  btn-success mg-l-10 float-right">{{ $t('actions.generatepassword') }}</a>
                        </div>
                    </div>
                    <div class="row mg-t-30">
                        <div class="col-4">
                        </div>
                        <div class="col-sm-8 mg-l-auto">
                            <div class="form-layout-footer">
                                <button class="btn btn-success mg-r-15" id="Submit" name="Submit" type="submit">{{ $t('actions.save') }}</button>
                            </div><!-- form-layout-footer -->
                        </div><!-- col-8 -->
                    </div>
                </div>

            </div>
        </form>
    </div>
</template>

<script>

    import toastr from 'toastr';
    import checkBeforeLeaveMixin from '@/mixin/index.js';

    export default {
        name: 'CustomerUserEdit',
        mixins: [checkBeforeLeaveMixin],
        data() {
            return {
                initialized: false,
                record: {
                    id: -1,
                    email: '',
                    name: '',
                    customerId: 0,
                    password: '',
                    isActive: true
                },

            };
        },
        mounted() {
            this.fetch();
        },
        watch: {
            '$route'() {
                this.fetch();
            },
            record: {
                handler() {
                    this.setDirty(true);
                },
                deep: true
            },


        },

        methods: {
            async fetch() {
                if (this.$route.params.userId > 0) {
                    const client = await this.$api.getClient();
                    const resp = await client.GetCustomerUser(this.$route.params.userId);
                    this.record = resp.data;
                    this.initialized = true;
                    this.$nextTick(function () { this.setDirty(false); });
                } else {
                    this.record.customerId = this.$route.params.id
                    this.initialized = true;
                    this.setDirty(false);

                }
            },
            async saveChanges() {
                const client = await this.$api.getClient();
                try {
                    await client.SaveCustomerUser(null, this.record);
                    this.setDirty(false);
                    toastr.success(this.$t('success.saveChanges'), '');
                    this.$router.push({ name: 'customerUsers', params: { id: this.$route.params.id } });

                } catch (err) {
                    if (err.response.status === 423) {
                        toastr.error(this.$t('error.emailexists'));
                    }
                    else {
                        toastr.error(this.$t('error.saveChanges'), err);
                    }
                }
            },
            async generatepassword() {
                const client = await this.$api.getClient();
                const resp = await client.GeneratePassword();
                this.record.password = resp.data;
            }
        }
    };

</script>
